import React, { useEffect } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"

import classes from "../components/single.module.css"
import Title from "../components/title/subtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const BlogCategory = ({ pageContext, data }) => {
  useEffect(() => {
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".col-6",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.3 }
    )
  }, [])

  const {
    allContentfulBlogPost: { edges: blogs },
  } = data
  return (
    <Layout>
      <SEO
        title={pageContext.name + "カテゴリーのブログ一覧"}
        description={
          "しょうのまきの" +
          pageContext.name +
          "に関するブログはこちらから。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。"
        }
        keywords={[
          `イラストデザイン`,
          `グラフィックデザイン`,
          `美容室`,
          `東京`,
        ]}
      />

      <header className="page-head">
        <Title title={pageContext.name} />
      </header>

      <article className="post-content page-template no-image">
        <section>
          <div className="row">
            {blogs.length > 0 ? (
              blogs.map(b => (
                <div className={`col-6 ${classes.blogArchive} ${b.node.id}`}>
                  <Link
                    className="post-archive-link"
                    to={`/blog/${b.node.slug}`}
                  >
                    {b.node.heroImage ? (
                      <Img
                        className="kg-image"
                        fluid={b.node.heroImage ? b.node.heroImage.fluid : null}
                        alt={b.node.title}
                      />
                    ) : (
                      <Img
                        className="kg-image"
                        fluid={data.file.childImageSharp.fluid}
                        alt="ブログの画像"
                      />
                    )}
                  </Link>

                  {b.node.category ? (
                    <p className={` ${classes.category}`}>
                      <Link to={`/blog/category/${b.node.category.slug}`}>
                        {b.node.category.name}
                      </Link>
                    </p>
                  ) : null}

                  <h2>
                    <Link to={`/blog/${b.node.slug}`}>{b.node.title}</Link>
                  </h2>
                  <div
                    className={classes.descArchive}
                    dangerouslySetInnerHTML={{
                      __html: b.node.description.childMarkdownRemark.html,
                    }}
                  />
                  <div className={`post-content-date ${classes.date}`}>
                    {b.node.publishDate}
                  </div>
                </div>
              ))
            ) : (
              <div className="post-content center">
                <div className="post-content-body">
                  <p>まだ記事がありません</p>
                </div>
              </div>
            )}
          </div>
        </section>

        <ul className="actions">
          {blogs.length > 10
            ? Array.from({ length: pageContext.numPages }).map(
                (page, index) => (
                  <li
                    key={index}
                    isCurrentPage={index + 1 === pageContext.currentPage}
                  >
                    <Link
                      className={"button primary"}
                      to={index === 0 ? "/blog" : `/blog/${index + 1}`}
                    >
                      {index + 1}
                    </Link>
                  </li>
                )
              )
            : null}
        </ul>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!, $slug: String!) {
    file(relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 768
          maxHeight: 500
          cropFocus: CENTER
          quality: 85
          traceSVG: { turnPolicy: TURNPOLICY_MINORITY, blackOnWhite: false }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    allContentfulBlogPost(
      filter: { category: { slug: { eq: $slug } } }
      limit: $limit
      skip: $skip
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          id
          publishDate(formatString: "YYYY年MM月DD日")
          category {
            name
            slug
          }
          heroImage {
            fluid(
              resizingBehavior: PAD
              maxWidth: 768
              maxHeight: 500
              quality: 85
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
export default BlogCategory
